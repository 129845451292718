.submenu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    @media only screen and (min-width: 900px) {
        align-items: stretch;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-template-rows: 1fr auto;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1180px;
    }
}
