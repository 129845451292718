.custom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    img {
        display: block;
        width: 100%;
        height: auto;
        @media only screen and (min-width: 900px) {
            margin: 0 -50px;
            max-width: min(366px, calc(100% + 100px));
        }
    }
    p {
        color: #345874;
        font-size: 16px;
        line-height: 1.6;
        font-weight: 400;

        [data-additional-attribute='has-new-layout'] & {
            color: inherit;
        }
        @media only screen and (min-width: 900px) {
            font-size: 14px;
            max-width: 250px;
        }
    }
    a {
        color: var(--primary-link-color);
        text-decoration: none;
        font-weight: 700;
        letter-spacing: 0.7px;
        line-height: 1.4;
        align-self: flex-start;
        font-size: 16px;
        padding: 0.8em 2em;
        margin: -0.8em -2em calc(-0.8em + 5px);
        text-transform: uppercase;
        @media only screen and (min-width: 900px) {
            font-size: 14px;
        }
        [data-additional-attribute='has-new-layout'] & {
            color: #00a2ff;
            text-transform: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &:hover {
            color: var(--primary-link-hover-color);
        }
        &:focus {
            color: var(--primary-link-focus-color);
        }
        &:active {
            color: var(--primary-link-active-color);
        }
    }
}

[data-additional-attribute='has-new-layout'] .arrow {
    display: none;
}
[data-additional-attribute='has-new-layout'] .darkMode {
    p {
        color: #cddbe5;
    }
}
