.dialog {
    border: none;
    display: block;
    height: 0;
    padding: 0;
    position: static;
    width: calc(100% + 2 * 18px);
    box-sizing: border-box;
    margin: 0 -18px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s ease 0.3s;
    background: #f8fafc;
    box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 16%);
    font-size: 16px;
    overflow: hidden;

    [data-additional-attribute='has-new-layout'] & {
        font-size: 14px;
        background-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid #d3d5d9;
    }
    @media only screen and (min-width: 900px) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: auto;
        margin: 0;
        box-shadow: inset 0 60px 10px -50px rgb(0 0 0 / 5%), 0 15px 15px 0 rgb(32 39 48 / 5%);

        [data-additional-attribute='has-new-layout'] & {
            background-color: rgba(245, 247, 250, 0.9);
            backdrop-filter: blur(13px);
            box-shadow: none;
        }
    }

    &[open],
    &:focus-within {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease, transform 0.3s ease;
        height: auto;
        transform: none;
    }
}
.darkMode {
    [data-additional-attribute='has-new-layout'] & {
        border-bottom: 1px solid rgba(245, 247, 250, 0.1);
        @media only screen and (min-width: 900px) {
            background: #000;
            .dialogContainer::before {
                background: rgba(245, 247, 250, 0.1);
            }
        }
    }
}
.dialogContainer {
    padding: 0 18px;
    max-width: 1180px;
    margin: 0 auto;
    @media only screen and (min-width: 900px) {
        padding: 0 20px;
        padding-top: 30px;

        [data-additional-attribute='has-new-layout'] & {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 20px;
                top: 0;
                height: 1px;
                width: calc(100% - 40px);
                background: #d3d5d9;
            }
        }
    }
}
.button {
    span {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        @media only screen and (min-width: 900px) {
            display: inline;
        }
    }
    svg {
        fill: currentColor;
        transform-origin: center;
        margin-inline-start: 7px;
    }
    svg:first-of-type {
        display: none;
        @media only screen and (min-width: 900px) {
            display: inline-block;
        }
    }
    svg:first-of-type + svg {
        @media only screen and (min-width: 900px) {
            display: none;
        }
    }
    &:hover,
    &:focus {
        svg:first-of-type {
            transform: rotateZ(90deg);
        }
    }
    &:hover .cloak {
        @media only screen and (min-width: 900px) {
            display: block;
        }
    }
}
.cloak {
    display: none;
    height: 100%;
    position: absolute;
    transition: visibility 0s ease 500ms;
    visibility: visible;
    width: 100%;
    top: -40px;
    padding: 40px 120px;
    left: -120px;

    &:hover {
        visibility: hidden;
    }
}
.wayback {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-bottom: 40px;
}
.button:has(+ .dialog[open]) .wayback {
    @media only screen and (min-width: 900px) {
        display: block;
    }
}
