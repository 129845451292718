body:has(.header) {
    --header-height: 88px;
    @media screen and (min-width: 900px) {
        --header-height: 75px;
    }
}
.header {
    background: #000;
    position: sticky;
    top: 0;
    z-index: 9999;
    width: 100%;
    box-shadow: 0 0 30px 0 rgba(32, 39, 48, 0.08);

    &[data-additional-attribute='has-new-layout'] {
        background: none;
        box-shadow: none;

        /* transition: all 1s ease; */

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            backdrop-filter: blur(13px);
        }
    }
    &[data-additional-attribute='has-new-layout'].stuck {
        border-bottom: 1px solid rgba(245, 247, 250, 0.1);

        &::before {
            background-color: #000;
        }
    }
    @media only screen and (min-width: 900px) {
        &[data-additional-attribute='has-new-layout'][data-has-dialog='open'] {
            &::before {
                background-color: #000;
            }
        }
        &[data-additional-attribute='has-new-layout'].stuck[data-has-dialog='open'] {
            border-bottom: none;
        }
    }

    &.not-sticky {
        @media screen and (min-width: 900px) {
            position: relative;
        }
    }
}

.inner {
    height: 88px;
    padding: 0 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    background: #000;
    max-width: 1180px;
    margin: 0 auto;
    font-size: 30px;
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 700;

    [data-additional-attribute='has-new-layout'] & {
        background: transparent;
        font-family: var(--rubik);
    }
    @media only screen and (min-width: 900px) {
        font-weight: 500;
        padding: 0 20px;
        height: 75px;
        font-size: 15px;
        font-family: revert;
    }
}
.mobileMenuToggler {
    order: 10;
    background: none;
    border: none;
    align-self: center;
    height: 22px;
    width: 28px;
    padding: 0;
    display: inline-flex;
    position: relative;
    @media only screen and (min-width: 900px) {
        display: none;
    }

    &::after {
        content: '';
        display: block;
        width: 28px;
        height: 2px;
        background: #fff;
        box-shadow: 0 10px 0 #fff, 0 20px 0 #fff;
    }

    &.menuVisible {
        &::before,
        &::after {
            content: '';
            display: block;
            width: 28px;
            height: 2px;
            background: #fff;
            box-shadow: none;
            transform-origin: center;
            position: absolute;
            top: 50%;
            left: 50%;
        }
        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
.searchLink {
    [data-additional-attribute='has-new-layout'] & {
        display: none;
    }

    &:hover {
        fill: var(--primary-link-hover-color);
    }
    &:focus {
        fill: var(--primary-link-focus-color);
    }
    &:active {
        fill: var(--primary-link-active-color);
    }

    span {
        @media only screen and (min-width: 900px) {
            display: none;
        }
    }
    svg {
        display: none;
        @media only screen and (min-width: 900px) {
            display: inline-block;
        }
    }
}
.nav {
    flex: 1 1 auto;
    display: none;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: calc(100vh - 88px);
    overflow: auto;
    background: #000;
    padding: 0 18px;
    box-sizing: border-box;

    &.visible {
        display: flex;
    }
    [data-additional-attribute='has-new-layout'] & {
        background-color: #000;
        backdrop-filter: blur(13px);
    }
    @media only screen and (min-width: 900px) {
        position: static;
        flex-direction: row;
        display: flex;
        width: auto;
        height: auto;
        gap: 30px;
        overflow: hidden;

        [data-additional-attribute='has-new-layout'] & {
            background: transparent;
            backdrop-filter: none;
            padding: 0 10px 0 18px;
        }
    }
}

.button {
    font-size: 12px;
    font-weight: 500;
    border-radius: 100px;
    box-sizing: border-box;
    padding: 0.5em 1.24em;
    letter-spacing: 0.04em;
    transition: all 0.1s ease-in-out;
    line-height: 1;
    margin: auto;
    text-decoration: none;
    border: 1px solid var(--primary-btn-bg-color);
    color: var(--primary-btn-text-color);
    background: var(--primary-btn-bg-color);

    [data-additional-attribute='has-new-layout'] & {
        font-size: 14px;
        font-weight: 700;
        border: 2px solid #3393f2;
        color: #fff;
        background: #00a2ff;
        border-radius: 12px;
        padding: 0.5em 0.8em;
        letter-spacing: 0;
    }
    @media only screen and (min-width: 376px) {
        font-size: 17px;
    }
    @media only screen and (min-width: 900px) {
        padding: 0.6em 1.24em;
        font-size: 13px;
        line-height: 1.4;

        [data-additional-attribute='has-new-layout'] & {
            padding: 0.6em 0.8em;
            font-size: 14px;
        }
    }

    &:hover,
    &:active {
        background: transparent;
        color: var(--primary-btn-hover-bg-color);
        border-color: var(--primary-btn-hover-bg-color);

        [data-additional-attribute='has-new-layout'] & {
            background: transparent;
            color: #3393f2;
        }
    }
}

.loginButton {
    [data-additional-attribute='has-new-layout'] & {
        @media only screen and (min-width: 900px) {
            font-size: 14px;
            font-weight: 700;
            box-sizing: border-box;
            transition: all 0.1s ease-in-out;
            margin: auto;
            text-decoration: none;
            border: 2px solid #b2bac1;
            color: #000;
            background: transparent;
            border-radius: 12px;
            padding: 0.6em 0.9em;
            line-height: 1.4;
        }

        &:hover,
        &:active {
            background: transparent;
            color: #3393f2;
            border-color: #3393f2;
        }
    }
}
.item {
    &.itemEnterprice {
        padding: 19px 0;
        align-items: start;
        @media screen and (min-width: 900px) {
            align-items: center;
        }
    }
    .enterprice {
        z-index: 1;
        color: #3393f2;
    }
}
