.list {
    list-style: none;
    padding: 0;
    margin: 11px 0;
    @media only screen and (min-width: 900px) {
        margin-bottom: 17px;
        max-width: min(320px, calc(100% - 30px));
    }
    li {
        overflow: hidden;
    }
}
.label {
    font-size: 17px;
    margin-top: 0.5em;
    margin-bottom: 0.85em;
    letter-spacing: 0.03em;
    font-family: var(--rubik);
    > * {
        color: #11161d;
        font-weight: 700;
        display: inline-flex;
        width: 100%;
        @media only screen and (min-width: 900px) {
            width: auto;
        }
    }
    a {
        text-decoration: none;
        position: relative;
        svg {
            stroke: #000;
        }
        &:hover {
            color: var(--primary-link-hover-color);
            svg {
                stroke: var(--primary-link-hover-color);
            }
        }
        &:focus {
            color: var(--primary-link-focus-color);
            svg {
                stroke: var(--primary-link-focus-color);
            }
        }
        &:active {
            color: var(--primary-link-active-color);
            svg {
                stroke: var(--primary-link-active-color);
            }
        }
    }
}
.horizontal {
    width: 100%;
    @media only screen and (min-width: 900px) {
        grid-column: 2 / span 4;
        grid-row: 3 / span 3;
        border-top: 1px solid #dadada;
    }
    .list {
        max-width: 100%;
        @media only screen and (min-width: 900px) {
            margin: 22px 0;
            display: grid;
            grid-template-columns: repeat(3, minmax(220px, 1fr));
            grid-template-rows: 1fr 1fr;
            flex: 1 100%;
            > * {
                flex-basis: 25%;
                > span {
                    padding: 11px 0;
                }
            }
        }
    }
}
.darkMode {
    @media only screen and (min-width: 900px) {
        &.horizontal {
            border-top: 1px solid rgba(245, 247, 250, 0.1);
        }
    }
    .label {
        svg {
            stroke: #fff;
        }
        > * {
            color: #fff;
        }
    }
}
.custom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 10;
    @media only screen and (min-width: 900px) {
        order: initial;
    }
}
