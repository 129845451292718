.icon {
    margin-right: 8px;
    align-self: flex-start;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
}
.item {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    font-family: var(--rubik);
    [data-additional-attribute='has-new-layout'] & svg {
        /* stylelint-disable-next-line declaration-no-important */
        color: #00a2ff !important;
    }
    @media only screen and (min-width: 900px) {
        padding: 14px 0;
        width: auto;
        &:has(p) {
            padding: 15px 0;
        }
    }
    span&,
    > div > span {
        line-height: 1;
        font-size: 14px;
        color: var(--primary-link-active-color);
    }
    a,
    a& {
        font-size: 14px;
        color: #000;
        text-decoration: none;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 0;
        padding: 0.8em 2em;
        margin: -0.8em -2em;
        flex-grow: 1;
        &:hover,
        &:focus,
        &:active {
            color: #00a2ff;
        }
    }
    p {
        font-size: 14px;
        line-height: 1.6;
        font-weight: 400;
        margin-bottom: -0.2em;
        color: inherit;
    }
}
.darkMode {
    a,
    a& {
        color: #fff;
    }
    p {
        color: #cddbe5;
    }
}
