.item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f0f2f5;
    @media only screen and (min-width: 900px) {
        align-items: center;
        border-bottom: none;
        display: inline-flex;
        flex-direction: row;
        padding: 0;
    }

    &:last-child {
        border: none;
    }

    > button {
        padding: 0;
        background: none;
        border: none;
        font-size: inherit;
        font-family: inherit;
        cursor: pointer;
    }

    > a,
    > button {
        color: #11161d;
        font-weight: 700;
        text-decoration: none;
        position: relative;
        display: flex;
        width: 100%;
        line-height: 1;
        padding: 19px 0;
        @media only screen and (min-width: 900px) {
            color: #11161d;
            font-weight: 500;
            padding: 0;
            display: inline-flex;
            width: 100%;
        }
        &:hover {
            @media only screen and (min-width: 900px) {
                color: var(--primary-link-hover-color);
            }
        }
        &:focus {
            @media only screen and (min-width: 900px) {
                color: var(--primary-link-focus-color);
            }
        }
        &:active {
            @media only screen and (min-width: 900px) {
                color: var(--primary-link-active-color);
            }
        }
    }
    > a {
        &:hover {
            color: var(--primary-link-hover-color);
        }
        &:focus {
            color: var(--primary-link-focus-color);
        }
        &:active {
            color: var(--primary-link-active-color);
        }
    }
    > span {
        position: relative;
        padding: 19px 0;
        color: var(--primary-brand-color-400);
    }
}
.alignEnd {
    @media only screen and (min-width: 900px) {
        margin-inline-start: auto;
    }
}
.darkMode.item {
    border-bottom: 1px solid rgba(245, 247, 250, 0.1);
    > a:not(:hover),
    > button:not(:hover) {
        color: #fff;
    }
    @media only screen and (max-width: 899px) {
        > a,
        > button {
            color: #fff;
        }
        button svg {
            filter: invert(1);
        }
    }
}
