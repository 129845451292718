.badge {
    background: var(--primary-brand-color-600);
    border-radius: 100px;
    padding: 3px 6px 2px;
    font-size: 10px;
    letter-spacing: 0.02em;
    color: var(--primary-brand-color-alternate-400);
    font-weight: 500;
    line-height: 1;
    position: absolute;
    top: 6px;
    left: 9px;
}
