.icon {
    width: 20px;
    height: 20px;
    margin-right: 14px;
    align-self: flex-start;
    flex-shrink: 0;
}
.item {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    font-family: 'RobotoFlex', sans-serif;
    [data-additional-attribute='has-new-layout'] & {
        font-family: var(--rubik);
    }

    [data-additional-attribute='has-new-layout'] & svg {
        /* stylelint-disable-next-line declaration-no-important */
        color: #00a2ff !important;
    }
    @media only screen and (min-width: 900px) {
        padding: 18px 0;
        width: auto;

        &:has(p) {
            padding: 15px 0;
        }
    }
    span&,
    > div > span {
        color: var(--primary-brand-color-400);
    }
    a,
    a& {
        color: #11161d;
        text-decoration: none;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 0.02em;
        padding: 0.8em 2em;
        margin: -0.8em -2em;
        flex-grow: 1;
        &:hover {
            color: var(--primary-link-hover-color);
            [data-additional-attribute='has-new-layout'] & {
                color: #00a2ff;
            }
        }
        &:focus {
            color: var(--primary-link-focus-color);
            [data-additional-attribute='has-new-layout'] & {
                color: #00a2ff;
            }
        }
        &:active {
            color: var(--primary-link-active-color);
            [data-additional-attribute='has-new-layout'] & {
                color: #00a2ff;
            }
        }
    }

    p {
        color: #345874;
        font-size: 14px;
        line-height: 1.6;
        font-weight: 400;
        margin-bottom: -0.2em;

        [data-additional-attribute='has-new-layout'] & {
            color: inherit;
        }
    }
}
[data-additional-attribute='has-new-layout'] .darkMode {
    a,
    a& {
        color: #fff;
    }
    p {
        color: #cddbe5;
    }
}
