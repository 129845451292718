.chevron {
    margin-left: 1.5px;
    svg {
        top: 1px;
        position: relative;
    }
}
span.highlight {
    color: var(--primary-link-active-color);
}
.topLabelEmail {
    color: var(--primary-brand-color-600);
    font-family: var(--rubik);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    padding: 1px 0 15px;
    @media only screen and (min-width: 900px) {
        grid-column: 1 / span 3;
    }
}
.topLabelContent {
    color: #39d464;
    font-family: var(--rubik);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    padding: 1px 0 15px;
    @media only screen and (min-width: 900px) {
        grid-column: 4 / span 4;
        grid-row: 1 / span 1;
    }
}
.labelMore {
    border-top: 1px solid #dadada;
    color: #717171;
    font-family: var(--rubik);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    padding-top: 20px;
    @media only screen and (min-width: 900px) {
        padding: 31px 0;
        grid-row: 3 / span 3;
        grid-column: 1 / span 1;
    }
}
.darkMode {
    .labelMore {
        color: #8e8e8e;
        border-top: 1px solid rgba(245, 247, 250, 0.1);
    }
}
