.valentinesBanner {
    background: linear-gradient(180deg, #f04 0%, #f04 15%, #0b3685 85%, #0b3685 100%), #0f44bc;
    padding: 32px 16px;
    position: sticky;
    top: var(--header-height);
    overflow: hidden;
    z-index: 9998;
    @media only screen and (min-width: 800px) {
        background: linear-gradient(90deg, #f04 0%, #f04 15%, #0045c3 85%, #0045c3 100%);
        padding: 10px 32px;
    }
    @media only screen and (max-height: 500px) {
        position: relative;
        top: 0;
    }
    .hidden {
        visibility: hidden;
    }
}

.container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -16px;
    width: calc(100% + 40px);
    margin-left: -40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media only screen and (min-width: 800px) {
        flex-wrap: nowrap;
    }
}

.box {
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
    position: relative;
    padding-left: 40px;
    padding-top: 16px;
    img {
        max-width: 202px;
        display: block;
        margin: 0 auto;
    }
}

.desktopBox {
    display: none;
    @media only screen and (min-width: 800px) {
        display: block;
    }
}

.backgroundBox {
    bottom: -77px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
    display: none;
    @media only screen and (min-width: 800px) {
        display: flex;
        justify-content: center;
    }
    img {
        max-width: 385px;
    }
    &.blueDecoration {
        left: 40px;
        top: -41px;
        img {
            max-width: 283px;
        }
    }
}

.typography {
    margin: 0;
    letter-spacing: 0.0094em;
    color: #fff;
    font-family: var(--rubik);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 694px;
}

.button {
    white-space: nowrap;
}
