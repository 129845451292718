.logo {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    align-self: center;
    overflow: hidden;
    width: 35px;
    svg {
        display: block;
        width: 174px;
        height: 23px;
        fill: #262626;
    }
    @media only screen and (min-width: 900px) {
        width: auto;
        margin-inline-end: 10px;
        margin-block-start: -2px;
        svg {
            width: 140px;
            height: 19px;
        }
        a {
            height: 19px;
        }
    }
}
.darkMode svg {
    fill: #fff;
}
