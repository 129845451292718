.list {
    list-style: none;
    padding: 0;
    @media only screen and (min-width: 900px) {
        max-width: min(320px, calc(100% - 30px));
    }

    li {
        overflow: hidden;
    }
}
.label {
    font-size: 22px;
    margin-top: 0.5em;
    margin-bottom: 0.6em;
    font-family: 'Source Sans 3', sans-serif;
    letter-spacing: 0.03em;

    [data-additional-attribute='has-new-layout'] & {
        font-size: 17px;
        font-family: var(--rubik);
    }

    > * {
        color: #11161d;
        font-weight: 700;
        display: inline-flex;
        width: 100%;
        @media only screen and (min-width: 900px) {
            width: auto;
        }
    }
    a {
        text-decoration: none;
        position: relative;

        &:hover {
            color: var(--primary-link-hover-color);
        }
        &:focus {
            color: var(--primary-link-focus-color);
        }
        &:active {
            color: var(--primary-link-active-color);
        }
    }
}
.noLabel {
    width: 100%;
    border-top: 1px solid #dadada;
    @media only screen and (min-width: 900px) {
        grid-column: 1 / span 4;
    }

    .list {
        max-width: 100%;
        @media only screen and (min-width: 900px) {
            margin-block-start: 1.5em;
            margin-block-end: 1.5em;
            display: flex;
            justify-content: space-between;
            > * {
                flex-basis: 25%;
            }
        }
    }
}
.darkMode {
    &.noLabel {
        border-top: 1px solid rgba(245, 247, 250, 0.1);
    }
    .label > * {
        color: #fff;
    }
}
.custom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 10;
    @media only screen and (min-width: 900px) {
        order: initial;
    }
}
